/**
 * Um simples plugin para saber o status atual de alguma chamada.
 * Utilize os métodos `setCall` para atualizar uma chamada passando uma chave única e o status, e o `clearCall` para limpar a chave.
 */
export default (context, inject) => {
  if (process.client) {
    const axiosState = {
      data: {},
      setCall(key, status) {
        this.data[key] = status
      },
      clearCall(key) {
        delete this.data[key]
      },
    }

    inject('axiosState', axiosState)
  }
}
