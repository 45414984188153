import { clearEmptyParams } from '~/utils/params'

export default ($axios) => ({
  findCoordenadas(empresa, modulo, item, filtros) {
    const parsedFilters = {
      ...filtros,
      items: filtros.tipoFicha,
      tipoFicha: undefined,
    }
    const params = clearEmptyParams(parsedFilters, true)

    return $axios.get(`mapas/${empresa}/${modulo}?${params}`)
  },

  exportKmzMapaAtivos(empresa, modulo, item, filtro) {
    const params = clearEmptyParams(filtro, true, true)

    return $axios.get(`mapas/${empresa}/kmzData/${modulo}/${item}?${params}`)
  },

  importarKmzMapaAmbiental(empresa, arquivo, titulo) {
    const formData = new FormData()
    formData.append('file', arquivo)
    formData.append('titulo', titulo)
    return $axios.post(`mapas/${empresa}/kmlData`, formData)
  },

  findImportsMapaAmbiental(empresa) {
    return $axios.get(`mapas/${empresa}/importacoes`)
  },

  gerarKmzMapaAmbiental(empresa, ids) {
    const params = new URLSearchParams({
      ids,
    })

    const devUrl = 'https://dev.gesconsys.com.br'
    const baseUrl =
      window.location.origin === devUrl ? devUrl + '/api' : process.env.BASE_URL

    const kmzUrl = `${baseUrl}/mapas/${empresa}/mapsKmlData?${params.toString()}`

    return kmzUrl
  },

  exportarKmlsMapaAmbiental(empresa, filtros, modulo = 'MAM') {
    const parsedFilters = {
      ...filtros,
      items: filtros.tipoFicha,
      tipoFicha: undefined,
    }
    const params = clearEmptyParams(parsedFilters, true)

    return $axios.get(
      `${
        process.env.BASE_URL
      }/mapas/${empresa}/downloadMapa/${modulo}?${params.toString()}`,
      {
        responseType: 'arraybuffer',
      }
    )
  },
})
