const URL_API = '/core'

export default ($axios) => ({
  findDicionarios(modulo) {
    return $axios.get(`${URL_API}/dicionarios/${modulo}`)
  },

  findEntidadeDicionario(modulo, entidade) {
    return $axios.get(`${URL_API}/dicionarios/${modulo}/${entidade}`)
  },

  findRefCodes(empresa, search) {
    if (search === undefined || search == null) search = ''

    return $axios.get(`${URL_API}/refCodes/${empresa}?search=${search}`)
  },

  /* Salva o RefCode enviado */
  saveRefCode(empresa, refCode) {
    return $axios.post(`${URL_API}/refCodes/${empresa}`, refCode)
  },

  /* Salva a lista de refcodes enviada */
  saveRefCodes(empresa, refCodes) {
    return $axios.post(`${URL_API}/refCodes/${empresa}/all`, refCodes)
  },

  getRefCodesGlobais(refCode) {
    return $axios.get(`${URL_API}/refCodes/globals/values/${refCode}`)
  },

  getAuteticacaoEmpresa() {
    return $axios.get(`${URL_API}/refCodes/empresaAutenticacao`)
  },

  getGrupoRegistro() {
    return $axios.get(`${URL_API}/refCodes/gruposRegistro`)
  }
})
