/**
 * Plugin para identificar e salvar a URL de entrada do usuário, assim como a empresa que já deve estar selecionada.
 */
export default (context, inject) => {
  if (process.client) {
    const urlEntrada = {
      data: {
        path: null,
        idEmpresa: null,
        first: true,
      },
      setRoute(route) {
        if (!this.data.first) return

        this.data.path = route.fullPath
        const idEmpresa = Number(route.query?.idEmpresa ?? 0)
        this.data.idEmpresa = idEmpresa ?? null

        this.data.first = false
      },
      getIdEmpresa() {
        return this.data.first ? this.data.idEmpresa : null
      },
      getPath() {
        return this.data.first ? this.data.path : null
      },
      clear() {
        this.data.idEmpresa = null
        this.data.path = null
        this.data.first = false
      },
    }

    inject('urlEntrada', urlEntrada)
  }
}
