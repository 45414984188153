export default function ({ route, $urlEntrada }) {
  if (
    route.path === '/login' ||
    route.path === '/selecaoEmpresa' ||
    route.path === '/registro' ||
    route.path ===
      `/confirm/${route.params.id !== undefined ? route.params.id : ''}`
  )
    return null

  $urlEntrada.setRoute(route)
}
