export default function (context, inject) {
  function notifyApiError(error, title = 'Erro') {
    this.$notify({
      title,
      text:
        error?.response?.data?.message || error?.response?.data?.detail || null,
      type: 'error',
    })
  }

  inject('notifyApiError', notifyApiError)
}
