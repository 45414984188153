//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
import menuItems from '../../../data/menu'
import menuExterno from '../../../data/menuExterno'

export default {
  props: {
    selectedParentMenu: {
      type: String,
      default: () => '',
    },
    viewingParentMenu: {
      type: String,
      default: () => '',
    },
    changeSelectedParentHasNoSubmenu: {
      type: Function,
      default: () => false,
    },
  },
  data() {
    return {
      iconDown: 'chevron-down',
    }
  },
  computed: {
    ...mapGetters({
      menuType: 'menu/getMenuType',
      menuClickCount: 'menu/getMenuClickCount',
      selectedMenuHasSubItems: 'menu/getSelectedMenuHasSubItems',
      isExterno: 'usuario/isExterno',
      permissoesUser: 'usuario/permissoes',
      moduloAtual: 'appData/modulo',
      empresa: 'empresa/empresaAtual',
    }),
  },
  methods: {
    getItensMenu() {
      const menuAux = []

      for (const menu of menuItems) {
        const subMenu = []
        let flagComPermissaoSubMenu = false
        let flagSemPermissaoSubMenu = false

        if (menu.modulo === this.moduloAtual) {
          if (menu.subs !== undefined) {
            if (menu.subs.length > 0) {
              for (const menuSub of menu.subs) {
                // Adiciona o item do subMenu se tiver permissão de acesso
                for (const permissao of this.permissoesUser) {
                  if (menuSub.permissao === permissao.nome) {
                    flagComPermissaoSubMenu = true
                    subMenu.push(menuSub)
                  }
                }

                // Adiciona os itens do subMenu que não tem permissões
                if (menuSub.permissao === undefined) {
                  if (flagComPermissaoSubMenu === false) {
                    flagSemPermissaoSubMenu = true
                    subMenu.push(menuSub)
                  } else {
                    subMenu.push(menuSub)
                  }
                }
              }
            }
          }
        }

        // Recria o subMenu de permissão
        if (flagComPermissaoSubMenu === true) {
          menuAux.push({
            id: menu.id,
            modulo: menu.modulo,
            icon: menu.icon,
            label: menu.label,
            to: menu.to,
            visivel: true,
            subs: subMenu,
          })
        } else if (flagSemPermissaoSubMenu === true) {
          // Adiciona os itens que estão sem permissao no menu (publicos ?)
          menuAux.push({
            id: menu.id,
            modulo: menu.modulo,
            icon: menu.icon,
            label: menu.label,
            to: menu.to,
            visivel: true,
            subs: subMenu,
          })
        }
      }

      return menuAux
    },

    getMenuExterno() {
      const menuAux = []

      for (const menu of menuExterno) {
        const subMenu = []
        let flagComPermissaoSubMenu = false
        let flagSemPermissaoSubMenu = false

        if (menu.modulo === this.moduloAtual) {
          if (menu.subs !== undefined) {
            if (menu.subs.length > 0) {
              for (const menuSub of menu.subs) {
                // Adiciona o item do subMenu se tiver permissão de acesso
                for (const permissao of this.permissoesUser) {
                  // CB: Adiciona o comentário na validação do dado módulo atual
                  if (menuSub.permissao === permissao.nome) {
                    flagComPermissaoSubMenu = true
                    subMenu.push(menuSub)
                  }
                }

                // Adiciona os itens do subMenu que não tem permissões
                if (menuSub.permissao === undefined) {
                  if (flagComPermissaoSubMenu === false) {
                    flagSemPermissaoSubMenu = true
                    subMenu.push(menuSub)
                  } else {
                    subMenu.push(menuSub)
                  }
                }
              }
            }
          }
        }

        // Recria o subMenu de permissão
        if (flagComPermissaoSubMenu === true) {
          menuAux.push({
            id: menu.id,
            modulo: menu.modulo,
            icon: menu.icon,
            label: menu.label,
            to: menu.to,
            visivel: true,
            subs: subMenu,
          })
        } else if (flagSemPermissaoSubMenu === true) {
          // Adiciona os itens que estão sem permissao no menu (publicos ?)
          menuAux.push({
            id: menu.id,
            modulo: menu.modulo,
            icon: menu.icon,
            label: menu.label,
            to: menu.to,
            visivel: true,
            subs: subMenu,
          })
        }
      }

      return menuAux
    },
  },
}
