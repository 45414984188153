// const URL_API = '/api'

export default ($axios) => ({
  login(userName, password) {
    return $axios.post(`/auth`, { userName, password }).then((user) => {
      // login successful if there's a jwt token in the response
      if (user.token) {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('token', JSON.stringify(user.token))
        localStorage.setItem('tipo', JSON.stringify(user.tipo))

        if (user.empresas) {
          localStorage.setItem('empresas', JSON.stringify(user.empresas))
          localStorage.setItem('empresaAtual', user.empresas[0].sigla)
        }

        // carrega as logomarcas
        this.$empresaService.fillLogomarcas()
      }

      return user
    })
  },

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('token')
    localStorage.removeItem('empresas')
    localStorage.removeItem('tipo')
    localStorage.removeItem('empresaAtual')
    sessionStorage.clear()
  },

  register(user) {
    return $axios.post(`/register`, user)
  },

  registerConfirm(payloadReset, userId) {
    payloadReset.login = userId
    return $axios.post(`/register/confirm/`, payloadReset)
  },

  getAllByEmpresa(empresa, filtro) {
    let parms = ''
    const sizeEmpresa = 0
    let sizePerfil = 0

    if (filtro) {
      const keys = Object.keys(filtro)
      for (const key of keys) {
        if (filtro[key] !== undefined && filtro[key] !== null) {
          if (Array.isArray(filtro[key])) if (filtro[key].length === 0) continue

          // Perfil
          if (key === 'perfil') {
            if (filtro.perfil.length > 0) {
              if (filtro.perfil.length > sizePerfil) {
                for (const perfil of filtro.perfil) {
                  if (parms !== '') parms += '&'
                  parms += key + '=' + perfil
                  sizePerfil++
                }
              }
            }
          }

          // Empresa
          if (key === 'empresas') {
            if (filtro.empresas.length > 0) {
              if (filtro.empresas.length > sizeEmpresa) {
                for (const empresa of filtro.empresas) {
                  if (parms !== '') parms += '&'
                  parms += key + '=' + empresa
                }
              }
            }
          }
        }

        if (key !== 'perfil' && key !== 'empresas') {
          if (filtro[key] !== null) {
            if (parms !== '') parms += '&'
            parms += key + '=' + filtro[key]
          }
        }
      }
      if (parms !== '') parms = '?' + parms
    }

    return $axios.get(`/usuarios/${empresa}/all${parms}`)
  },

  getUserById(idUsuario) {
    return $axios.get(`/usuarios/${idUsuario}/usuario`)
  },

  getUserSavedById(idUsuario) {
    return $axios.get(`/usuarios/${idUsuario}/usuario/saved`)
  },

  deleteUser(idUsuario) {
    return $axios.delete(`/usuarios/${idUsuario}/deleteUsuario/`)
  },

  getUser() {
    return $axios(`/usuarios/info`)
  },

  getUserByLogin() {
    return $axios(`/usuarios/info`).then((user) => {
      return user
    })
  },

  saveUsuario(usuario) {
    console.log('Passei aqui=>', usuario)
    return $axios.post(`/usuarios/usuario`, usuario)
  },

  getGruposByUser() {
    return $axios.get(`/usuarios/grupos`)
  },

  getEmpresasByUser() {
    return $axios.get(`/usuarios/empresas`)
  },

  findPermissoesByUser() {
    return $axios(`/usuarios/permissoes`)
  },

  findPermissoes(modulo) {
    return $axios(`/usuarios/permissoes/all`)
  },

  findGrupos() {
    return $axios.get(`/seguranca/grupos`)
  },

  resetPassword(login, user) {
    return $axios.put(`/usuarios/${login}/alterarSenha`, user)
  },
  getUsuarioByEmpresa(siglaEmpresa, search) {
    if (search === null || search === undefined) {
      return $axios.get(`/usuarios/${siglaEmpresa}/all`)
    } else {
      return $axios.get(`/usuarios/${siglaEmpresa}?search=${search}`)
    }
  },
})
