export const clearEmptyParams = (
  params,
  toString = false,
  duplicateArrays = false
) => {
  const copyFilters = { ...params }

  Object.keys(copyFilters).forEach((key) => {
    const value = copyFilters[key]
    if (
      value === undefined ||
      value === null ||
      value === '' ||
      (Array.isArray(value) && value.length === 0)
    ) {
      delete copyFilters[key]
    }
  })

  if (!toString) return new URLSearchParams({ ...copyFilters })

  if (duplicateArrays) return getParamsStringDuplicatedArrays(copyFilters)

  return new URLSearchParams({ ...copyFilters }).toString()
}

export const getParamsStringDuplicatedArrays = (params) => {
  const paramsArray = []

  Object.keys(params).forEach((key) => {
    if (Array.isArray(params[key])) {
      params[key].forEach((item) => {
        paramsArray.push(key + '=' + item)
      })
    } else {
      paramsArray.push(key + '=' + params[key])
    }
  })

  return paramsArray.join('&')
}
