//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapMutations, mapActions } from 'vuex'
// import Switches from "vue-switches";
import notifications from '../../data/notifications'
import ModalAlterarSenha from '~/components/modalAlterarSenha.vue'

import version from '~/data/version'

import {
  searchPath,
  menuHiddenBreakpoint,
  localeOptions,
  defaultColor,
  themeSelectedColorStorageKey,
} from '~/constants/config'

export default {
  name: 'TopNav',
  components: {
    ModalAlterarSenha,
  },
  data() {
    return {
      searchKeyword: '',
      isMobileSearch: false,
      isSearchOver: false,
      fullScreen: false,
      menuHiddenBreakpoint,
      searchPath,
      localeOptions,
      notifications,
      isDarkActive: false,
      empresaAtual: { razaoSocial: null },
      user: {},
      logomarca: undefined,

      // Versão do Sistema
      version,

      // Notificação
      notificacoes: [
        {
          id: 1,
          dias: -956,
          siglaEmpresa: 'OTI',
          descricao: 'Teste de nova correspondência',
        },
        {
          id: 2,
          dias: -864,
          siglaEmpresa: 'OTI',
          descricao: 'Teste 25/05/2021',
        },
        {
          id: 3,
          dias: -620,
          siglaEmpresa: 'OTI',
          descricao: 'Assunto: 27 Relatório Semestral de Acampamento Ambiental',
        },
        {
          id: 4,
          dias: -390,
          siglaEmpresa: 'OTI',
          descricao: 'Declaração de Regularidade Ambiental',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'menu/currentUser',
      usuario: 'usuario/usuario',
      menuType: 'menu/getMenuType',
      menuClickCount: 'menu/getMenuClickCount',
      selectedMenuHasSubItems: 'menu/getSelectedMenuHasSubItems',
      logomarcas: 'logomarca/getLogomarcas',
      empresas: 'empresa/empresas',
    }),
  },
  watch: {
    isDarkActive(val) {
      let color = this.getThemeColor()
      let isChange = false
      if (val && color.includes('light') > -1) {
        isChange = true
        color = color.replace('light', 'dark')
      } else if (!val && color.includes('dark') > -1) {
        isChange = true
        color = color.replace('dark', 'light')
      }
      if (isChange) {
        localStorage.setItem(themeSelectedColorStorageKey, color)
        setTimeout(() => {
          // window.location.reload()
        }, 500)
      }
    },
    isMobileSearch(val) {
      if (val) {
        document.addEventListener('click', this.handleDocumentforMobileSearch)
      } else {
        document.removeEventListener(
          'click',
          this.handleDocumentforMobileSearch
        )
      }
    },
  },
  mounted() {
    this.empresaAtual = this.$store.getters['empresa/empresaAtual']
    this.user = this.$store.state.auth.user
    this.getLogomarca()
  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleDocumentforMobileSearch)
  },
  created() {
    const color = this.getThemeColor()
    this.isDarkActive = color.includes('dark') > -1
  },
  methods: {
    ...mapMutations({
      changeSideMenuStatus: 'menu/changeSideMenuStatus',
      changeSideMenuForMobile: 'menu/changeSideMenuForMobile',
    }),
    ...mapActions({ logout: 'usuario/logout' }),
    search() {
      this.$router.push(`${this.searchPath}?search=${this.searchKeyword}`)
      this.searchKeyword = ''
    },
    searchClick() {
      if (window.innerWidth < this.menuHiddenBreakpoint) {
        if (!this.isMobileSearch) {
          this.isMobileSearch = true
        } else {
          this.search()
          this.isMobileSearch = false
        }
      } else {
        this.search()
      }
    },
    handleDocumentforMobileSearch() {
      if (!this.isSearchOver) {
        this.isMobileSearch = false
        this.searchKeyword = ''
      }
    },

    changeLocale(locale) {
      this.setLang(locale)
    },
    handleAdminPage() {
      this.$router.push('/admin')
    },
    handleLogout() {
      this.$router.push('/logout')
      this.logomarca = undefined
    },

    getLogomarca() {
      this.logomarca = undefined
      if (this.empresaAtual !== undefined) {
        if (this.logomarcas !== null) {
          for (const item of this.logomarcas) {
            if (item.empresa === this.empresaAtual.sigla) {
              this.logomarca = 'data:image/png;base64,' + item.imagem
              break
            }
          }
        }

        if (this.logomarca === undefined)
          this.logomarca = process.env.VUE_APP_CONTEXT + 'img/oti-slim.png'
      }
    },

    toggleFullScreen() {
      const isInFullScreen = this.isInFullScreen()

      const docElm = document.documentElement
      if (!isInFullScreen) {
        if (docElm.requestFullscreen) {
          docElm.requestFullscreen()
        } else if (docElm.mozRequestFullScreen) {
          docElm.mozRequestFullScreen()
        } else if (docElm.webkitRequestFullScreen) {
          docElm.webkitRequestFullScreen()
        } else if (docElm.msRequestFullscreen) {
          docElm.msRequestFullscreen()
        }
      } else if (document.exitFullscreen) {
        document.exitFullscreen()
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen()
      }

      this.fullScreen = !isInFullScreen
    },
    getThemeColor() {
      return localStorage.getItem(themeSelectedColorStorageKey)
        ? localStorage.getItem(themeSelectedColorStorageKey)
        : defaultColor
    },
    isInFullScreen() {
      return (
        (document.fullscreenElement && document.fullscreenElement !== null) ||
        (document.webkitFullscreenElement &&
          document.webkitFullscreenElement !== null) ||
        (document.mozFullScreenElement &&
          document.mozFullScreenElement !== null) ||
        (document.msFullscreenElement && document.msFullscreenElement !== null)
      )
    },
    trocarEmpresa() {
      this.$router.push('/selecaoEmpresa')
    },

    openModalAlterarSenha() {
      this.$bvModal.show('editSenha')
    },
  },
}
